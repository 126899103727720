<template>
    <el-main>
        <div class="cards">
            <el-card class="box-card">
                <div class="flex_row">
                    <div>提现账号</div>
                    <div style="font-weight:400;">银行卡</div>
                </div>
                <div class="card_info" style="height: 50px">{{ card.bank_info }}
                </div>
                <div style="display:flex;justify-content: flex-end;">
                    <el-button size="mini" type="primary" @click="dialogVisible = true">编辑</el-button>
                </div>
            </el-card>
            <el-card class="box-card">
                <div>待结算金额（待对账）</div>
                <div class="num">￥{{ amount.wait_settle? amount.wait_settle: 0}}</div>
            </el-card>
            <el-card class="box-card">
                <div>可提现金额（待提现）</div>
                <div class="num">￥{{ amount.total_settlement_price?amount.total_settlement_price:0 }}</div>
            </el-card>
            <div style="margin-left:50px; display: flex;align-items: center;">
                <el-button type="primary" @click="dialogVisible_withdraw = true">全部提现</el-button>
            </div>
        </div>
        <el-table :data="list" row-key="id" default-expand-all :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="order_sn" label="订单编号" align="center"></el-table-column>
            <el-table-column prop="artisan_name" label="技工姓名" align="center">
            </el-table-column>
            <el-table-column prop="artisan_mobile" label="联系电话" align="center">
            </el-table-column>
            <el-table-column prop="total_price" label="订单金额" align="center">
            </el-table-column>
            <el-table-column prop="settlement_price" label="结算金额" align="center">
                <template v-slot="{ row }">￥{{ row.settlement_price? row.settlement_price :''}}</template>
            </el-table-column>
            <el-table-column prop="money" label="结算状态" align="center">
                <template v-slot="{ row }">
                <div style="color: orange" v-if="row.status==1">待对账</div>
                <div style="color: orange" v-else-if="row.status==2 && !row.withdraw_id">可提现</div>
                <div style="color: orange" v-else-if="row.status==2 && row.withdraw_id">提现中</div>
                <div style="color: green" v-else-if="row.status==3">平台确认可提现</div>
                <div style="color: red" v-else-if="row.status==4">平台驳回重新对账</div>
                </template>
            </el-table-column>
            <el-table-column prop="money" label="订单完成时间" align="center">
                <template v-slot="{ row }">{{ getDateformat(row.finish_time) }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                 <template v-slot="{ row }">
                        <el-button  type="text" size="mini" @click.stop="detail(row)">详情</el-button>
                        <el-button v-if="row.status == 1 || row.status == 4" type="text" size="mini" @click.stop="checkAccount(row)">订单对账</el-button>
                        <el-button v-if="row.status == 4" type="text" size="mini" @click.stop="rejectReason(row)">驳回原因</el-button>
                 </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>

        <el-dialog title="提现账号" :visible.sync="dialogVisible" width="40%">
            <el-form label-width="100px" :model="card" :rules="cardrules" ref="card">
                <el-form-item label="开户行">
                    <el-input size="small" v-model="card.bank_info" placeholder="请输入开户行"></el-input>
                </el-form-item>
                <el-form-item label="银行卡号">
                    <el-input size="small" v-model="card.bank_card" placeholder="请输入银行卡号"></el-input>
                </el-form-item>
                <el-form-item label="持卡人">
                    <el-input size="small" v-model="card.bank_name" placeholder="请输入持卡人"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmCard">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="提现账号" :visible.sync="dialogVisible_withdraw" width="40%">
            <el-form label-width="140px" label-position="left">
                <el-form-item label="提现账号">
                    <div>银行卡</div>
                </el-form-item>
                <el-form-item label="开户行">
                    <div>{{ card.bank_info }}</div>
                </el-form-item>
                <el-form-item label="银行卡号">
                    <div>{{ card.bank_card }}</div>
                </el-form-item>
                <el-form-item label="持卡人">
                    <div>{{ card.bank_name }}</div>
                </el-form-item>
                <el-form-item label="提现金额">
                    <div style="font-size: 30px;">￥{{ amount.total_settlement_price }}</div>
                </el-form-item>
                <el-form-item label="请确认是否提现？"></el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible_withdraw = false">取 消</el-button>
                <el-button type="primary" @click="handleWithdraw">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="驳回原因" :visible.sync="checkingAccount" width="40%">
            <el-form label-width="140px" label-position="left">
                <el-form-item label="驳回原因:">
                    <div>{{refuse_reason}}</div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="checkingAccount = false">取 消</el-button>
                <el-button type="primary" @click="checkingAccount=false">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog v-if="info.order" title="对账" :visible.sync="dialogVisible_check" width="40%" :before-close="handleClose">
            <div>
                <el-main class="contentBox">
                    <div class="user-info">
                        <div class="replenish">
                            <!-- <div class="imageSpan"> -->

                            <!-- <el-image style="width: 60px; height: 60px" src=""></el-image> -->
                            <!-- </div> -->
                            <div>
                                <span>服务名称：</span>
                                {{ info.order.service_name }}
                            </div>
                            <div>
                                <span>服务订单号：</span>
                                {{ info.order.order_sn }}
                            </div>
                            <div class="flexWrap">
                                <div>
                                    <span>提交时间：</span>
                                    {{ getDateformat(info.order.create_time) }}
                                </div>
                                <div>
                                    <span>尾款时间：</span>
                                    {{ getDateformat(info.order.final_pay_time) }}
                                </div>
                                <div style="display: flex">
                                    <span>下单联系人：</span>
                                    {{ info.order.name }}
                                    <div>{{ info.order.is_vip ? 'Vip' : '非Vip' }}</div>
                                </div>
                                <div>
                                    <span>技工姓名：</span>
                                    {{ info.artisan_name }}
                                </div>
                                <div>
                                    <span>下单人联系电话：</span>
                                    {{ info.order.mobile }}
                                </div>
                                <div>
                                    <span>技工联系电话：</span>
                                    {{ info.artisan_mobile }}
                                </div>
                            </div>

                            <div style="color: blue; margin-bottom: 10px">
                                <span>订单支付金额：</span>
                                {{ info.order.total_price }}
                            </div>
                            <div style="color: blue">
                                <span>人工工时费：</span>
                                {{ (info.order.mechanic_time - info.order.vip_no_hour_cost) * info.order.one_hour_cost }}
                            </div>
                            <div>
                                <span>开始时间：</span>
                                {{ getDateformat(info.order.mechanic_start_time) }}
                            </div>
                            <div style="display: flex; justify-content: space-between; align-items: center">
                                <div>
                                    <div style="padding-bottom: 10px">
                                        <span>结束时间：</span>
                                        {{ getDateformat(info.order.mechanic_end_time) }}
                                    </div>
                                    <div>
                                        <span>工时费单价：</span>
                                        ￥ {{ info.order.one_hour_cost }}元/时

                                        <div style="margin-left: 100px; padding: 10px 0">
                                            <span>工时：</span>
                                            {{ info.order.mechanic_time }}小时
                                        </div>
                                        <div style="margin-left: 100px; padding-bottom: 10px">
                                            <span>VIP免费工时：</span>
                                            {{ info.order.vip_no_hour_cost }}小时
                                        </div>
                                    </div>
                                </div>
                                <div style="font-size: 40px; color: red">￥{{ info.hourly_price }}</div>
                            </div>

                            <div style="display: flex; justify-content: space-between; align-items: center">
                                <div style="color: blue; width: 50%">
                                    <span>维修材料费：</span>
                                    ￥{{ info.order.mechanic_material_price }}
                                </div>
                                <div style="width: 50%; display: flex; justify-content: space-between; align-items: center">
                                    <span>工时对账金额：</span>
                                    <input type="text"  v-model="checking.hourly_price"
                                        style="width: 100px; height: 30px; border: 1px solid #ccc; padding: 0 10px; text-align: right" />
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: space-between; align-items: center">
                            <div style="padding-bottom: 10px; width: 50%">
                                <span>维修材料清单：</span>
                            </div>
                            <div style="padding-bottom: 10px; width: 50%">
                                <span>物业对账金额：</span>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: space-between; align-items: center">
                            <div style="padding-bottom: 10px; width: 50%">
                                <span></span>
                            </div>
                            <div style="padding-bottom: 10px; width: 50%; display: flex; align-items: center">
                                <span style="display: block; width: 40%">材料名称</span>
                                <span style="display: block; width: 40%">材料单价</span>
                                <span style="display: block; width: 30%">材料数量</span>
                            </div>
                        </div>
                        <div style="display: flex; padding-bottom: 10px">
                            <div style="display: flex; flex-direction: column; width: 50%">
                                <div style="display: flex; padding-bottom: 10px; width: 100%"
                                    v-for="(item, index) in mechanic_material_te" :key="index">
                                    <div
                                        style="display: flex; justify-content: space-between; width: 200px; margin-right: 50px">
                                        <span>{{ item.name }}：</span>
                                        ￥{{ item.price }}
                                    </div>
                                    <div style="display: flex; justify-content: space-between; width: 100px">
                                        <span>数量：</span>
                                        {{ item.num }}
                                    </div>
                                    <div style="width: 70px; text-align: center">→</div>
                                </div>
                            </div>

                            <div style="display: flex; flex-direction: column; width: 50%">
                                <div style="width: 100%; display: flex; justify-content: space-between; align-items: center"
                                    v-for="(value, index) in checking.mechanic_material" :key="value.id">
                                    <div style="width: 26%"><input type="text" v-model="value.name"
                                            style="width: 70%; height: 30px; border: 1px solid #ccc; padding: 0 10px" />
                                    </div>
                                    <div style="width: 26%"><input type="text" v-model="value.num"
                                            style="width: 70%; height: 30px; border: 1px solid #ccc; padding: 0 10px" />
                                    </div>
                                    <div style="width: 20%"><input type="text" v-model="value.price"
                                            style="width: 70%; height: 30px; border: 1px solid #ccc; padding: 0 10px" />
                                    </div>
                                    <div style="color: blue; width: 28px; margin-left: 6px" v-if="index >= 1"
                                        @click="delService(index)">删除</div>
                                    <div style="color: blue; width: 28px; margin-left: 6px" v-else></div>
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div
                            style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 30px">
                            <div style="padding-bottom: 10px; width: 50%">
                                <span></span>
                            </div>
                            <div style="color: blue; width: 50%" @click="addService">+添加其他材料项目</div>
                        </div>
                        <div style="display: flex; justify-content: space-between; align-items: center">
                            <div style="padding-bottom: 10px; width: 50%">
                                <span></span>
                            </div>
                            <div
                                style="width: 50%; display: flex; justify-content: space-between; align-items: center; padding-top: 30px; border-top: 1px solid #000">
                                <el-button type="primary" size="small">材料总计</el-button>
                                <span style="color: red; font-size: 28px">￥{{ mechanic_material_price }}</span>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: space-between; align-items: center">
                            <div style="padding-bottom: 10px; width: 50%">
                                <span></span>
                            </div>
                            <div
                                style="width: 50%; display: flex; justify-content: space-between; align-items: center; padding-top: 30px">
                                <el-button type="info" size="small">总结算金额</el-button>
                                <span style="color: red; font-size: 28px">￥{{ settlement_price }}</span>
                            </div>
                        </div>
                    </div>
                </el-main>
            </div>

            <span slot="footer" class="dialog-footer" v-if="detailFlag">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            card: {
                bank_name: "",
                bank_card: "",
                bank_info: ""
            },
            detailFlag:true,
            list: [],
            form: {
                page: 1, 
                rows: 10,
            },
            checking:{
                hourly_price:0,
                mechanic_material:[],
            },
            info:[],
            dialogVisible_check:false,
            total_number: 0,
            amount: [],
            mechanic_material_te:[],
            dialogVisible: false,
            currentInfo:[],
            checkingAccount:false,
            cardrules: {
                bank_name: [{ required: true, message: '请输入开户行', trigger: 'blur' }],
                bank_card: [{ required: true, message: '请输入银行卡号', trigger: 'blur' }],
                bank_name: [{ required: true, message: '请输入持卡人', trigger: 'blur' }]
            },
            settlement_price:0,
            mechanic_material_price:0,
            refuse_reason:'',
            dialogVisible_withdraw: false
        };
    },
    watch: {
    // 每当 question 改变时，这个函数就会执行
      'checking.mechanic_material': {
      handler(newQuestion, oldQuestion) {
        console.log(newQuestion,oldQuestion)
        let price = 0;
        for(let i = 0;i<newQuestion.length;i++){
            price += newQuestion[i].price * newQuestion[i].num;
        }
        this.mechanic_material_price = price
        this.settlement_price = parseFloat(price)+parseFloat(this.checking.hourly_price)
      },
      deep: true
    },
    'checking.hourly_price': {
      handler(newQuestion, oldQuestion) {
        console.log(newQuestion,oldQuestion)
        this.settlement_price = parseFloat(this.mechanic_material_price)+parseFloat(this.checking.hourly_price)
      },
      deep: true
    }
  },
    created () {
        this.getBankCard();
        this.getAmount()
        this.getList()
    },
    methods: {
        getDateformat,
        updateData (val, status) {
            if (status == 0) {
                this.form.rows = val;
                this.getList();
            } else {
                this.form.page = val;
                this.getList();
            }
        },
        addService () {
            this.checking.mechanic_material.push({
                name: '',
                num: '',
                price: '',
            });

            console.log(this.checking.mechanic_material);
        },
        // 删除材料
        delService (id) {
            let index = this.checking.mechanic_material.findIndex(item => item.id == id);
            this.checking.mechanic_material.splice(index, 1);
        },
        rejectReason(item){
            this.checkingAccount = true;
            this.refuse_reason = item.refuse_reason
        },
        onSubmit () {
            let data = this.checking
            data.id = this.currentInfo.id
            console.log(data)
            this.$axios.post(this.$api.finance.checking, data).then(res => {
                if (res.code == 0) {
                   this.dialogVisible_check = false;
                    this.getList();
                    this.getAmount()
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        checkAccount(item){
            this.dialogVisible_check = true;
            this.currentInfo = item
            this.$axios.post(this.$api.finance.getCheckingDetail, { id: item.id }).then(res => {
                if (res.code == 0) {
                    this.info = res.result;
                    this.mechanic_material_te = JSON.parse(JSON.stringify(this.info.order.mechanic_material_d));
                    this.checking.mechanic_material = this.info.order.mechanic_material_d;
                    console.log(this.info);
                } else {
                    this.$message.error(res.msg);
                }
            });

        },
        getBankCard () {
            this.$axios
                .post(this.$api.finance.getBankCard)
                .then(res => {
                    if (res.code == 0) {
                        this.card = res.result
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        getAmount () {
            this.$axios
                .post(this.$api.finance.getAmount)
                .then(res => {
                    if (res.code == 0) {
                        this.amount = res.result
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        detail(item){
            this.dialogVisible_check = true;
            this.currentInfo = item
            this.$axios.post(this.$api.finance.getCheckingDetail, { id: item.id }).then(res => {
                if (res.code == 0) {
                    this.info = res.result;
                    this.mechanic_material_te = JSON.parse(JSON.stringify(this.info.order.mechanic_material_d));
                    this.checking.mechanic_material = this.info.mechanic_material_d;
                    this.checking.hourly_price=this.info.hourly_price
                    this.settlement_price = this.info.settlement_price
                    this.mechanic_material_price = this.info.mechanic_material_price
                    this.detailFlag=false
                    console.log(this.info);
                } else {
                    this.$message.error(res.msg);
                }
            });

        },
        getList () {
            this.$axios
                .post(this.$api.finance.financialVerify, this.form)
                .then(res => {
                    if (res.code == 0) {
                        this.list = res.result.list
                        this.total_number = res.result.total
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        confirmCard () {
            this.$refs['card'].validate((valid) => {
                if (valid) {
                    this.$axios
                        .post(this.$api.finance.editBankCard, this.card)
                        .then(res => {
                            if (res.code == 0) {
                                this.$message.success('保存成功')
                                this.dialogVisible = false
                                this.getBankCard();
                            }
                        });
                } else {
                    return false;
                }
            });
        },
        handleWithdraw () {
            this.$axios
                .post(this.$api.finance.withdraw)
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success('提现成功')
                        this.dialogVisible_withdraw = false
                        this.getAmount()
                        this.getList()
                    }else{
                       this.$message.error(res.msg);
                    }
                });
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .cards {
        display: flex;
        margin-bottom: 20px;

        .box-card {
            width: 300px;
            font-weight: bold;

            .flex_row {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .num {
                font-size: 40px;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .card_info {
                font-weight: 400;
                line-height: 60px;
            }
        }

        .box-card+.box-card {
            margin-left: 50px;
        }
    }

    .el-table {
        flex: 1;

        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }

        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
    }

    .edit {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
        }

        i {
            font-size: 17px;
        }
    }

    .move .item {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-bottom: 20px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    // 这是点击后的旋转角度
    transform: rotate(0deg);
}

/deep/.el-table__expand-icon {
    .el-icon-arrow-right:before {
        //这是收起图标
        content: '\e723';
        font-size: 16px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    .el-icon-arrow-right:before {
        // 这是展开图标
        content: '\e722';
        font-size: 16px;
    }
}

.descriptions {
    font-size: 18px;
    font-weight: bold;
    padding: 30px 0;
    display: flex;

    .descriptions_item+.descriptions_item {
        margin-left: 50px;
    }
}
.flexWrap {
    display: flex;
    flex-wrap: wrap;
}

.replenish>div {
    //   width: 50%;
    padding-bottom: 10px;
}

.flexWrap>div {
    width: 50%;
    padding-bottom: 10px;
}

.replenish>div>span {
    display: inline-block;
    //   margin-left: 20px;
}

</style>
